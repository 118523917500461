import Polygon from 'ol/geom/Polygon';
import { interpolateBasis, quantize, zip } from 'd3';

export function calculatePolygonShape({ center, heading, dimensions: { toBow: top, toStern: bottom, toPort: left, toStarboard: right } }) {
	// We need to perform calculations using projected coordinates [x, y]
	// instead of [lon, lat] to simplify maths
	//const center = projectCoordinates(lon, lat);
	const box = createBox(center, [top, bottom, left, right]);
	const propsBox = centerCoordinatesBox(box[0], box[1], box[2], box[3], center, heading);
	const centerbox = propsBox.centerbox;
	const polygon = createRoundedPolygonFromBox(box);

	// Rotate the polygon based on heading, and center as pivot
	polygon.rotate(toRadians(heading), center);

	return { polygon, centerbox };
}

function centerCoordinatesBox(minX, minY, maxX, maxY, center, heading) {
	var coord = [
		[minX, minY],
		[minX, maxY],
		[maxX, maxY],
		[maxX, minY],
		[minX, minY]
	];
	const polygonBox = new Polygon([coord]);
	polygonBox.rotate(toRadians(heading), center);
	const extent = polygonBox.getExtent();
	const centerX = (extent[0] + extent[2]) / 2; // Promedio de minX y maxX
	const centerY = (extent[1] + extent[3]) / 2; // Promedio de minY y maxY
	const centerbox = [centerX, centerY];
	return { centerbox };
}

function createBox([x, y], [top, bottom, left, right]) {
	const xmin = x - left * 1.25;
	const xmax = x + right * 1.25;
	const ymin = y - bottom * 1.25;
	const ymax = y + top * 1.25;

	return [xmin, ymin, xmax, ymax];
}

function createRoundedPolygonFromBox([xmin, ymin, xmax, ymax]) {
	const addHeight = (ymax - ymin) * 0.95;
	const removeWidth = (xmax - xmin) * 0.2;

	const bl = [xmin + removeWidth, ymin];
	const br = [xmax - removeWidth, ymin];
	const peak = [xmin + (xmax - xmin) / 2, ymax];

	const arcPoints = [bl, [xmin, ymin], [xmin, ymin + addHeight], peak, [xmax, ymin + addHeight], [xmax, ymin], br];

	const xAxis = arcPoints.map(([x]) => x);
	const yAxis = arcPoints.map(([, y]) => y);

	const arc = zip(quantize(interpolateBasis(xAxis), 100), quantize(interpolateBasis(yAxis), 100));

	const coordinates = [...arc, bl];

	return new Polygon([coordinates]);
}

function toRadians(angle) {
	return (angle * Math.PI) / 180;
}
