<template>
	<div class="map-container" ref="map">
		<map-update-control />
		<map-popup :stop="featureHover"></map-popup>
		<map-zooms-control :currentmap="currentmap" />
		<map-legend-control :currentmap="currentmap" />
		<map-base-layer-control />
	</div>
</template>

<script>
/** Components */
import MapZoomsControl from './subcomponents/MapZoomsControlMap';
import MapPopup from './subcomponents/MapPopup';
import MapLegendControl from './subcomponents/MapLegendBody';
import MapBaseLayerControl from './subcomponents/MapBaseLayerControl';
import MapUpdateControl from './subcomponents/MapUpdateControl';

/** Constants */
import { TYPE_VIS, CENTER, INITIAL_ZOOM, MIN_ZOOM_BOLLARDS_BERTHS } from './subcomponents/mapVariables';
import ol2map from './subcomponents/ol2map';
import {
	//STOPS
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	// BERTHS AND BOLLARDS
	BERTHS_LAYER_ID,
	BOLLARDS_LAYER_ID,
	//AIS
	AIS_VESSEL_LAYER_ID,
	//CEP
	CEP_ZONES_ID,
	SELECT_STOP_LAYER_ID
} from '@/components/operations/map/constants/layers';

/** Utils */
import { getPanelOptions } from '@/lib/utils/features';
import mapUtils from '../../mapUtils';
import mapFeatureStyleUtils from '../../mapFeatureStyleUtils';

/** Creators */
import StopsFeatureCreator from '@/components/operations/map/creators/StopsFeatureCreator';
import AISTrackEventPointFeatureCreator from '@/components/operations/map/creators/AISTrackEventPointFeatureCreator';
import AISTrackEventVesselLineFeatureCreator from '@/components/operations/map/creators/AISTrackEventVesselLineFeatureCreator';
import MovementSequenceFeatureCreator from '@/components/operations/map/creators/MovementSequenceFeatureCreator';

/** Styles */
import bollardStyle from '@/components/operations/map/styles/bollardStyle';
import berthStyle from '@/components/operations/map/styles/berthStyle';
import zonesStyle from '@/components/operations/map/styles/zonesStyle';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';
import StopsServiceMixin from '@/mixins/StopsServiceMixin';
import LayersActiveMixin from '@/mixins/LayersActiveMixin';
import TrackServiceMixin from '@/mixins/TrackServiceMixin';
import MovementSequenceMixin from '@/mixins/MovementSequenceMixin';

/** 3rd-party */
import 'ol/ol.css';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Attribution from 'ol/control/Attribution';
import { Polygon } from 'ol/geom';

const attribution = new Attribution({
	collapsible: false
});

const optsBollardsBerthsLayer = {
	minZoom: MIN_ZOOM_BOLLARDS_BERTHS,
	zIndex: 250 // Por encima de los barcos 100-200
};

export default {
	name: 'MapPanelMap',

	mixins: [BaseLayersMixin, StopsServiceMixin, LayersActiveMixin, TrackServiceMixin, MovementSequenceMixin],

	components: {
		MapPopup,
		MapZoomsControl,
		MapLegendControl,
		MapBaseLayerControl,
		MapUpdateControl
	},

	props: {
		currentmap: {
			type: String,
			required: true
		},
		cepZones: {
			type: Array,
			required: true
		},
		stopsProcessed: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			lastFeatureOverlay: null,
			stopSelectLayer: null,
			portInfo: null,
			portExtensions: null,
			featureHover: null,
			isBerthsAddedLayer: false,
			isBollardsAddedLayer: false
		};
	},

	created() {},

	mounted() {},

	destroyed() {
		this.stopsFeatureCreator.terminateWorkers();
		this.$store.commit('setMapAisLoaded', false);
		this.$puiEvents.$off('map:updated', this.clearBerthBoatsMap);
	},

	computed: {
		activePanel() {
			return this.$store.getters.activePanelAIS;
		},

		activePanelOptionsAIS() {
			return this.$store.getters.activePanelOptionsAIS;
		},

		dynamicPanels() {
			return this.$store.getters.dynamicPanelsAIS;
		},

		mapLoaded() {
			return this.$store.state.map.mapLoaded;
		},

		berths() {
			return this.$store.getters.berths;
		},

		bollards() {
			return this.$store.getters.bollards;
		},

		optsZonesLayer() {
			return {
				visible: this.layerZonesActive,
				zIndex: 10
			};
		}
	},

	methods: {
		createMap(maxExtent) {
			this.$nextTick(() => {
				this.resolveMap(maxExtent);
			});
			this.$nextTick(() => {
				this.registerMapEvents();
			});
			this.$nextTick(this.setupMap);
		},
		resolveMap(maxExtent) {
			var self = this;
			const mapCanvas = this.$refs.map;
			const popup = document.getElementById('map-popup-overlay');

			this.overlay = new Overlay({
				zIndex: 9000, // Numero alto para que siempre este por encima
				element: popup,
				autoPan: false, // True: Ajustar al contenido para que se vea el popup
				autoPanAnimation: {
					duration: 250
				}
			});

			const acceptLayerVisibility = this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID][STOP_ACCEPTED_LAYER_ID];
			const plannedLayerVisibility = this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID][STOP_PLANNED_LAYER_ID];
			const authorizedLayerVisibility = this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID][STOP_AUTHORIZED_LAYER_ID];
			const confirmedLayerVisibility = this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID][STOP_CONFIRMED_LAYER_ID];
			const initiatedLayerVisibility = this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID][STOP_INITIATED_LAYER_ID];

			ol2map.init({
				// the map will be created using the 'map' ref
				controls: [attribution],
				target: mapCanvas,
				layers: [
					this.initialBaseLayerMap,
					ol2map.createVectorLayerFeatures(BERTHS_LAYER_ID, [], berthStyle, optsBollardsBerthsLayer),
					ol2map.createVectorLayerFeatures(BOLLARDS_LAYER_ID, [], bollardStyle, optsBollardsBerthsLayer),
					ol2map.createVectorLayerFeatures(CEP_ZONES_ID, [], zonesStyle, this.optsZonesLayer),
					this.stopsFeatureCreator.getAcceptedLayer(acceptLayerVisibility),
					this.stopsFeatureCreator.getPlannedLayer(plannedLayerVisibility),
					this.stopsFeatureCreator.getAuthorizedLayer(authorizedLayerVisibility),
					this.stopsFeatureCreator.getConfirmedLayer(confirmedLayerVisibility),
					this.stopsFeatureCreator.getInitiatedLayer(initiatedLayerVisibility),
					// Track event Point layer
					this.aisTrackEventPointFeatureCreator.getTrackEventPointLayer(),
					// Track event Line vessel layer
					this.aisTrackEventVesselLineFeatureCreator.getTrackEventVesselLineLayer(),
					this.aisTrackEventVesselLineFeatureCreator.getTrackEventVesselLinePointLayer(), // Esta es para pintar los arrow events
					// Movement sequence layer
					this.movementSequenceFeatureCreator.getMovementSequenceLayer(),
					// SelectLayer
					this.stopsFeatureCreator.getStopSelectLayer()
				],
				overlays: [this.overlay],
				// the map view will initially show the whole world
				view: new View({
					zoom: INITIAL_ZOOM,
					center: CENTER,
					extent: maxExtent
					//constrainResolution: true
				})
			});

			// Go to port if fit extent exist
			const fitExtent = this.$store.getters.getMapAisFitPortExtent;
			if (fitExtent) {
				ol2map.zoomToExtent(fitExtent);
			}
		},

		openPanel(identifier, data) {
			const options = getPanelOptions(identifier, data);

			if (options.panelId == this.activePanel) {
				return;
			}

			this.$store.commit('addDynamicPanelAIS', options);
			this.$store.commit('setActivePanelAIS', options);
		},

		/**
		 * Fired when panel changes
		 */
		onActivePanelChange(id) {
			const options = this.dynamicPanels.find(({ panelId }) => panelId === id) || {};

			if (this.lastFeatureOverlay) {
				this.lastFeatureOverlay.un('change:centerbox', this.updateOverlayCoordinates);
			}

			if (options.coordinates) {
				if (options.data.isAis) {
					var feature = ol2map.getFeatureAisBymmsi(options.data.mmsi);
					this.centerMap([feature.get('lon'), feature.get('lat')]);
					this.selectStop(options, feature);
				} else {
					this.centerMap(options.coordinates);
					this.selectStop(options);
				}
			} else {
				this.stopsFeatureCreator.clearSelectStops();
			}
		},

		centerMap(coordinates) {
			const duration = 300;
			const zoom = 16;
			ol2map
				.getMapInstance()
				.getView()
				.animate({
					center: [coordinates[0], coordinates[1]],
					duration,
					zoom
				});
		},

		selectStop(options, feature = null) {
			this.stopsFeatureCreator.clearSelectStops();
			// Get the feature if is AIS
			if (options.data.isAis) {
				this.lastFeatureOverlay = feature;
				this.stopsFeatureCreator.addFeatureSelectStop(
					[this.lastFeatureOverlay.get('centerbox')[0], this.lastFeatureOverlay.get('centerbox')[1]],
					this.lastFeatureOverlay.get('vessellength')
				);
				// Create event to update select feature
				this.updateSelectCoordinates();
			} else {
				this.stopsFeatureCreator.addFeatureSelectStop(options.coordinates, options.data.vessellength);
			}
		},

		updateSelectCoordinates() {
			// Crear y agregar un evento personalizado para el elemento feature
			this.lastFeatureOverlay && this.lastFeatureOverlay.on('change:centerbox', this.updateOverlayCoordinates);
		},

		updateOverlayCoordinates(event) {
			this.stopsFeatureCreator.clearSelectStops();
			var newLon = event.target.get('centerbox')[0];
			var newLat = event.target.get('centerbox')[1];
			var vessellength = event.target.get('vessellength');
			this.stopsFeatureCreator.addFeatureSelectStop([newLon, newLat], vessellength);
		},

		/**
		 * Setup map & layers
		 */
		async setupMap() {
			// Add berts and bollards layers
			this.addBerthsLayer();
			this.addBollardsLayer();
		},

		addBerthsLayer() {
			const berthsFeatures = this.stopsFeatureCreator.processBerths(this.berths);
			this.stopsFeatureCreator.setBerthLayer(ol2map.getLayerById(BERTHS_LAYER_ID));
			ol2map.addFeaturesToVectorLayer(BERTHS_LAYER_ID, berthsFeatures);
			this.isBerthsAddedLayer = true;
		},

		addBollardsLayer() {
			const bollardFeatures = this.stopsFeatureCreator.processBollards(this.bollards);
			this.stopsFeatureCreator.setBollardLayer(ol2map.getLayerById(BOLLARDS_LAYER_ID));
			ol2map.addFeaturesToVectorLayer(BOLLARDS_LAYER_ID, bollardFeatures);
			this.isBollardsAddedLayer = true;
		},

		onStopsCreated() {
			//this.$puiNotify.info(`${this.$t('operations.stops_created')}`);

			// Update layers Features data
			const acceptFeatures = ol2map.getFeaturesLayerById(STOP_ACCEPTED_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});
			const plannedFeatures = ol2map.getFeaturesLayerById(STOP_PLANNED_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});
			const authorizedFeatures = ol2map.getFeaturesLayerById(STOP_AUTHORIZED_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});
			const confirmedFeatures = ol2map.getFeaturesLayerById(STOP_CONFIRMED_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});
			const initiatedFeatures = ol2map.getFeaturesLayerById(STOP_INITIATED_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});

			this.$store.commit('setStopsFeaturesAccepted', acceptFeatures);
			this.$store.commit('setStopsFeaturesPlanned', plannedFeatures);
			this.$store.commit('setStopsFeaturesAuthorized', authorizedFeatures);
			this.$store.commit('setStopsFeaturesConfirmed', confirmedFeatures);
			this.$store.commit('setStopsFeaturesInitiated', initiatedFeatures);
		},

		processStops(stops) {
			stops.length &&
				this.isBerthsAddedLayer &&
				this.isBollardsAddedLayer &&
				this.stopsFeatureCreator.processStops(
					[...stops].sort((a, b) => {
						return (a.vesselname ?? '').localeCompare(b.vesselname);
					})
				);

			// Si no hay stops no los procesamos pero hay que actualizar los datos para el panel
			if (!stops.length) {
				this.$store.commit('setStopsFeaturesAccepted', []);
				this.$store.commit('setStopsFeaturesPlanned', []);
				this.$store.commit('setStopsFeaturesAuthorized', []);
				this.$store.commit('setStopsFeaturesConfirmed', []);
				this.$store.commit('setStopsFeaturesInitiated', []);
			}
			if (!this.isBerthsAddedLayer && !this.isBollardsAddedLayer) {
				console.log('Berths and bollards not processed');
			}
		},

		clearBerthBoatsMap() {
			this.stopsFeatureCreator && this.stopsFeatureCreator.clearBerthBoatsMap();
		},

		/**
		 * Extension
		 */
		getExtensions() {
			const workingPortsId = window.localStorage.getItem('workingPortsId');

			if (workingPortsId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: workingPortsId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					this.portInfo = response.data.data[0];
					this.portExtensions = response.data.data.map((objeto) => {
						const { id, portlocode, portauthorityname, bbox } = objeto;
						return { id, portlocode, portauthorityname, bbox };
					});
					this.$store.commit('setMapPortsExtents', this.portExtensions);
				});
			}
		},

		changeFeaturesStyle(resolution) {
			var self = this;
			ol2map.getLayerById(STOP_ACCEPTED_LAYER_ID) &&
				ol2map.getLayerById(STOP_ACCEPTED_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_ACCEPTED_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});
			ol2map.getLayerById(STOP_PLANNED_LAYER_ID) &&
				ol2map.getLayerById(STOP_PLANNED_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_PLANNED_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});

			ol2map.getLayerById(STOP_AUTHORIZED_LAYER_ID) &&
				ol2map.getLayerById(STOP_AUTHORIZED_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_AUTHORIZED_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});

			ol2map.getLayerById(STOP_CONFIRMED_LAYER_ID) &&
				ol2map.getLayerById(STOP_CONFIRMED_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_CONFIRMED_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});

			ol2map.getLayerById(STOP_INITIATED_LAYER_ID) &&
				ol2map.getLayerById(STOP_INITIATED_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_INITIATED_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});

			ol2map.getLayerById(AIS_VESSEL_LAYER_ID) &&
				ol2map.getLayerById(AIS_VESSEL_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(AIS_VESSEL_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureAIS(
							feature,
							resolution,
							feature.getProperties().geometryBoat,
							feature.getProperties().lon,
							feature.getProperties().lat,
							self.$store.getters.getMapAisStyleVisualization
						);
						feature.changed();
					});
		},

		/**
		 * Map events
		 */
		registerMapEvents() {
			var self = this;
			const mapCanvas = this.$refs.map;

			ol2map.getMapInstance().once('rendercomplete', function () {
				self.$store.commit('setMapAisLoaded', true);
			});

			ol2map.getMapInstance().on('moveend', function () {
				self.$store.commit('setMapZoomAIS', ol2map.getZoom());
				self.$store.commit('setMapBbox', ol2map.getMapExtent());
			});

			ol2map.getMapInstance().on(
				'pointermove',
				(evt) => {
					const hit = ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
						if (layer && layer.get('id') === SELECT_STOP_LAYER_ID) {
							return undefined;
						}
						return feature;
					});

					if (hit) {
						this.featureHover = hit.getProperties();
						// Solo pintamos un popup si se trata de un barco o un evento
						if (
							(this.featureHover.vesselimo ||
								this.featureHover.isAis ||
								this.featureHover.isEventWaypoint ||
								this.featureHover.isEventLine ||
								this.featureHover.isMovementWaypoint ||
								this.featureHover.idLayer == CEP_ZONES_ID) &&
							this.featureHover.featureVisible
						) {
							mapCanvas.style.cursor = 'pointer';
							this.overlay.setPosition(evt.coordinate);
						} else {
							mapCanvas.style.cursor = '';
							this.overlay.setPosition(undefined);
							this.featureHover = null;
						}
					} else {
						mapCanvas.style.cursor = '';
						this.overlay.setPosition(undefined);
						this.featureHover = null;
					}
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);

			ol2map.getMapInstance().on(
				'click',
				(evt) => {
					ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						if (feature.getProperties().isAis && feature.getProperties().featureVisible) {
							var identifier = feature.getProperties().isAis ? feature.getProperties().mmsi : feature.getProperties().id;
							this.openPanel(identifier, feature.getProperties());
						} else {
							if (feature.getProperties().featureVisible && feature.getProperties().vesselimo) {
								var identifier = feature.getProperties().id;
								this.openPanel(identifier, feature.getProperties());
							}
						}
					});
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);

			ol2map
				.getMapInstance()
				.getView()
				.on(
					'change:resolution',
					(evt) => {
						var resolution = evt.target.getResolution();
						self.changeFeaturesStyle(resolution);
					},
					{
						layerFilter: (layer) => layer instanceof VectorLayer
					}
				);
		}
	},
	watch: {
		activePanel: {
			handler(newVal, oldVal) {
				this.onActivePanelChange(newVal);
			},
			deep: true
		},
		'$store.state.mappanelais.activePanelOptions.vistrack': {
			// Este metodo se lanzara cuando se cambie el valor del switch de isActiveTrackPoint
			handler(newVal) {
				this.aisTrackEventPointFeatureCreator.clearTrack();
				this.aisTrackEventVesselLineFeatureCreator.clearTrack();
				if (newVal) {
					const vistrack = this.activePanelOptionsAIS.vistrack;
					if (!this.activePanelOptionsAIS.vistrack.isClean) {
						this.drawTrackEventPoints(
							this.aisTrackEventPointFeatureCreator,
							this.aisTrackEventVesselLineFeatureCreator,
							vistrack,
							this.activePanelOptionsAIS.data.mmsi,
							this.activePanelOptionsAIS.data.imo
						);
					}
				}
			},
			deep: true
		},

		'$store.state.mappanelais.activePanelOptions.seqMovementVis': {
			// Este metodo se lanzara cuando se cambie el valor del switch de isActiveTrackPoint
			handler(newVal) {
				this.movementSequenceFeatureCreator.clearTrack();
				if (newVal) {
					var stop_id = this.activePanelOptionsAIS.data.id;
					this.drawMovementSequence(this.movementSequenceFeatureCreator, stop_id);
				}
			},
			deep: true
		},

		cepZones(features) {
			const featuresGeometry = ol2map.processFeaturesFromWktToFeatures(features, 'Polygon');
			ol2map.addFeaturesToVectorLayer(CEP_ZONES_ID, featuresGeometry);
		},

		stopsProcessed(stops) {
			this.stopsFeatureCreator.clearStops(STOP_ACCEPTED_LAYER_ID);
			this.stopsFeatureCreator.clearStops(STOP_PLANNED_LAYER_ID);
			this.stopsFeatureCreator.clearStops(STOP_AUTHORIZED_LAYER_ID);
			this.stopsFeatureCreator.clearStops(STOP_CONFIRMED_LAYER_ID);
			this.stopsFeatureCreator.clearStops(STOP_INITIATED_LAYER_ID);
			this.processStops(stops);
		},

		portInfo(newVal) {
			// Limit extent
			const maxExtent = mapUtils.polygonWktToOlExtent(newVal.bboxextent);
			this.$store.commit('setMapAutorityExtent', maxExtent);

			// Go to port if not multiport
			if (this.portExtensions.length == 1) {
				const fitExtent = mapUtils.polygonWktToOlExtent(newVal.bbox);
				this.$store.commit('setMapAisFitPortExtent', fitExtent);
				this.$store.commit('setMapPeriodsFitPortExtent', fitExtent);
			}

			this.createMap(maxExtent);
		},

		'$store.state.mapconfig.stopsTypeValues'() {
			// Creators
			this.stopsFeatureCreator = new StopsFeatureCreator(
				(stopsCreated) => this.onStopsCreated(stopsCreated),
				this.$store.getters.getMapAisStyleVisualization,
				4
			);
			this.stopsFeatureCreator.setSearchingTextVessel(this.$store.state.mappanelais.searchingtext);
			this.aisTrackEventPointFeatureCreator = new AISTrackEventPointFeatureCreator();
			this.aisTrackEventVesselLineFeatureCreator = new AISTrackEventVesselLineFeatureCreator();
			this.movementSequenceFeatureCreator = new MovementSequenceFeatureCreator();

			this.$puiEvents.$on('map:updated', this.clearBerthBoatsMap);

			// Check if extensions exist
			const mapAutorityExtent = this.$store.getters.mapAutorityExtent;
			if (mapAutorityExtent !== null) {
				this.createMap(mapAutorityExtent);
			} else {
				this.getExtensions();
			}
		},

		// Watch para saber cuando ha cambiado el estilo del mapa
		'$store.state.mappanelais.mapStyleVisualization'(value) {
			this.stopsFeatureCreator.setModeVisualization(value);
			const resolution = ol2map.getMapInstance().getView().getResolution();
			this.changeFeaturesStyle(resolution);
		},

		// Watch para saber cuando ha cambiado searchtext
		'$store.state.mappanelais.searchingtext'(searchtext) {
			this.stopsFeatureCreator.setSearchingTextVessel(searchtext);
		}
	}
};
</script>

<style scoped>
.map-container {
	width: calc(100% + 42px);
	margin-left: -42px;
	height: 100%;
	position: relative;
}
.ol-attribution {
	left: 0px;
}
</style>
