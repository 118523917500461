import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

import { getHeading } from '@/services/DockAligner.js';
import { calculatePolygonShape } from '@/services/VesselShapeCalculator.js';
import mapUtils from '../mapUtils';
import mapFeatureStyleUtils from '../mapFeatureStyleUtils';
import { alignVessel } from '@/services/VesselAligner.js';

/** Constants */
import { AIS_VESSEL_LAYER_ID } from '@/components/operations/map/constants/layers';

/** 3rd-party */
import Feature from 'ol/Feature';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';

class AISVesselsFeatureCreator {
	vesselsVectorSource = new VectorSource({ features: [] });
	vesselsLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: AIS_VESSEL_LAYER_ID
		},
		source: this.vesselsVectorSource,
		zIndex: 100
		//style: aisStyle
	});

	modeVisualization = null;
	searchTextVessel = '';
	isFilterAisVisitLayerActive = true;
	isFilterServiceLayerActive = true;
	isFilterOtherVesselLayerActive = true;
	isFilterHighFrequencyLayerActive = true;

	constructor(modeVisualization) {
		this.modeVisualization = modeVisualization;
	}

	setSearchingTextVessel(searchtext) {
		this.searchTextVessel = searchtext;
	}

	setIsFilterVisitLayerActive(value) {
		this.isFilterAisVisitLayerActive = value;
	}

	setIsFilterServiceLayerActive(value) {
		this.isFilterServiceLayerActive = value;
	}

	setIsFilterOtherVesselLayerActive(value) {
		this.isFilterOtherVesselLayerActive = value;
	}

	setIsFilterHighFrequencyLayerActive(value) {
		this.isFilterHighFrequencyLayerActive = value;
	}

	setModeVisualization(value) {
		this.modeVisualization = value;
	}

	getVesselsLayer() {
		return this.vesselsLayer;
	}

	/**
	 *
	 * @param {Object} vessel The AIS vessel object to be displayed as a Boat
	 */

	_createOneVesselmarker(vessel, berths) {
		const {
			mmsi,
			imo,
			vesselName,
			vesselTypeDesc,
			locodeDesc,
			eta,
			lastUpdate,
			originalHeading,
			lat,
			lon,
			backgroundColor,
			foregroundColor,
			speed,
			draught,
			callSign,
			portCallNumber,
			isService,
			otherVessel,
			isPilot,
			isTug,
			otherVesselServices,
			statusid,
			iniBollardCode,
			endBollardCode,
			draftEntrance,
			draftOutlet,
			originCode,
			destinationCode,
			highFrequency
		} = vessel;

		var { toBow, toStern, toPort, toStarboard } = vessel;

		if (lat > 90 || (lat < -90 && lon > 180) || lon < -180) {
			console.log('Vessel coordinates error');
			return null;
		}

		if (toBow == 0 && toStern == 0 && toPort == 0 && toStarboard == 0) {
			toBow = 16;
			toStern = 8;
			toPort = 4;
			toStarboard = 4;
			console.log('Automatic vessel');
			//return null;
		}

		const pointGeometry = { type: 'Point', coordinates: [lat, lon] };
		const pointFeature = { type: 'Feature', geometry: pointGeometry };

		const heading = getHeading(pointFeature, berths, originalHeading) * -1; // -1 for counter-clockwise

		const projCoord = mapUtils.projectCoordinates(lon, lat);
		const projLon = projCoord[0];
		const projLat = projCoord[1];

		let polyShape = calculatePolygonShape({
			center: projCoord,
			heading,
			dimensions: { toBow, toStern, toPort, toStarboard }
		});

		let geometry = polyShape.polygon;
		const centerbox = polyShape.centerbox;

		const vesselbeam = toPort + toStarboard;
		const vessellength = toBow + toStern;

		// let geometryArrow = calculateArrowPolygonShape({
		// 	center: projCoord,
		// 	heading,
		// 	dimensions: { toBow: 100, toStern: 100, toPort: 25, toStarboard: 25 }
		// });

		let rotation = (heading * -1 * Math.PI) / 180;

		// Solo cuando esta parado y cerca del muelle alinearemos con el muelle
		if (speed == 0) {
			//let valores = alignVessel(geometry, berths, rotation, projCoord, imo, vesselbeam);
			//geometry = valores[0];
			//rotation = valores[1];
		}

		var feature = ol2map.getFeatureAisBymmsi(mmsi);

		const resolution = ol2map.getMapInstance().getView().getResolution();
		if (feature) {
			mapFeatureStyleUtils.setStyleFeatureAIS(feature, resolution, geometry, projLon, projLat, this.modeVisualization);

			feature.set('geometryBoat', geometry);
			//feature.set('geometryArrowBoat', geometryArrow);
			feature.set('rotation', rotation);
			feature.set('backgroundColor', backgroundColor);
			feature.set('foregroundColor', foregroundColor);

			let isVisible = true;
			const searchTextLower = this.searchTextVessel.toLowerCase();

			const isVesselNameMatch = vesselName ? vesselName.toLowerCase().includes(searchTextLower) : false;
			const isMMSIMatch = mmsi ? mmsi.toString().toLowerCase().includes(searchTextLower) : false;
			const isTypeMatch = vesselTypeDesc ? vesselTypeDesc.toLowerCase().includes(searchTextLower) : false;

			isVisible = isVesselNameMatch || isMMSIMatch || isTypeMatch;

			if (otherVessel) {
				isVisible = this.isFilterOtherVesselLayerActive && isVisible;
			}
			if (isService) {
				isVisible = this.isFilterServiceLayerActive && isVisible;
			} else {
				if (highFrequency && portCallNumber) {
					isVisible = (this.isFilterAisVisitLayerActive || this.isFilterHighFrequencyLayerActive) && isVisible;
				} else if (portCallNumber) {
					isVisible = this.isFilterAisVisitLayerActive && isVisible;
				} else if (highFrequency) {
					isVisible = this.isFilterHighFrequencyLayerActive && isVisible;
				}
			}

			feature.set('featureVisible', isVisible);

			feature.set('lat', projLat);
			feature.set('lon', projLon);
			feature.set('centerbox', centerbox);
			// Actualizar campos dinamicos como la velocidad
			feature.set('speed', speed);
			feature.set('eta', eta);
			feature.changed();

			return null;
		} else {
			const feature = new Feature({ geometry });
			var visible = true;

			const properties = {
				idLayer: AIS_VESSEL_LAYER_ID,
				isAis: true,
				mmsi,
				imo,
				vesselname: vesselName,
				vesseltype: vesselTypeDesc,
				locodeDesc,
				eta,
				lastUpdate,
				heading,
				originalHeading,
				lat: projLat,
				lon: projLon,
				centerbox,
				geometryBoat: geometry,
				//geometryArrowBoat: geometryArrow,
				vessellength: vessellength,
				vesselbeam: vesselbeam,
				rotation: rotation,
				featureVisible: visible,
				backgroundColor: backgroundColor,
				foregroundColor: foregroundColor,
				draught,
				speed,
				callSign,
				portCallNumber,
				isService,
				otherVessel,
				isPilot,
				isTug,
				otherVesselServices,
				statusid,
				iniBollardCode,
				endBollardCode,
				draftEntrance,
				draftOutlet,
				originCode,
				destinationCode,
				highFrequency
			};
			feature.setProperties(properties);

			let isVisible = true;
			const searchTextLower = this.searchTextVessel.toLowerCase();
			if (vesselName) {
				isVisible = vesselName.toLowerCase().includes(searchTextLower);
			}
			if (mmsi) {
				isVisible = isVisible || mmsi.toString().toLowerCase().includes(searchTextLower);
			}
			if (vesselTypeDesc) {
				isVisible = isVisible || vesselTypeDesc.toLowerCase().includes(searchTextLower);
			}

			if (otherVessel) {
				isVisible = this.isFilterOtherVesselLayerActive && isVisible;
			}
			if (isService) {
				isVisible = this.isFilterServiceLayerActive && isVisible;
			} else {
				if (highFrequency && portCallNumber) {
					isVisible = (this.isFilterAisVisitLayerActive || this.isFilterHighFrequencyLayerActive) && isVisible;
				} else if (portCallNumber) {
					isVisible = this.isFilterAisVisitLayerActive && isVisible;
				} else if (highFrequency) {
					isVisible = this.isFilterHighFrequencyLayerActive && isVisible;
				}
			}

			feature.set('featureVisible', isVisible);

			mapFeatureStyleUtils.setStyleFeatureAIS(feature, resolution, geometry, projLon, projLat, this.modeVisualization);
			feature.changed();
			this.vesselsVectorSource.addFeature(feature);
			return properties;
		}
	}

	_processVessel(vessel, berths) {
		//const portId = window.localStorage.getItem('workingPortsId');
		const properties = this._createOneVesselmarker(vessel, berths);
		return properties;
	}

	clearVessels() {
		this.vesselsLayer.getSource() && this.vesselsLayer.getSource().clear();
	}
}
export default AISVesselsFeatureCreator;
